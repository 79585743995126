import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from 'react-i18next';
import UserService from "../../services/UserService";
import moment from "moment";
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const UserClientInfoNext = (props) => {
    const history = useHistory();
    let params = useParams();
    const isAnonyme = !!params.id;
    const [address, setAddress] = useState(localStorage.getItem('address') ? JSON.parse(localStorage.getItem('address')) : null);
    const [confidentiality, setConfidentiality] = useState(localStorage.getItem('confidentiality') ? localStorage.getItem('confidentiality') : '');
    const [streetName, setStreetName] = useState(address ?  address.streetName : '');
    const [streetNumber, setstreetNumber] = useState(address ?  address.streetNumber : '');
    const [additionalAddress, setAdditionalAddress] =useState(address ?  address.additionalAddress : '');
    const [postCode, setPostCode] = useState(address ?  address.postalCode : '');
    const [city, setCity] = useState(address ? address.city : '');
    const [validStreetName, setvalidStreetName] = useState(false);
    const [validStreetNumber, setvalidStreetNumber] = useState(false);
    const [validAdditionalAddress, setvalidStreetAdditionalAddress] = useState(false);
    const [validPostCode, setvalidPostCode] = useState(false);
    const [validCity, setvalidCity] = useState(false);
    const [car, setCar] = useState(JSON.parse(localStorage.getItem('car')));
    const [startDate, setStartDate] = useState(new Date());
    const [customer, setCustomer] = useState(JSON.parse(localStorage.getItem('customer')));
    const [email, setEmail] = useState(localStorage.getItem('email')  ? localStorage.getItem('email')  : '');

    const handleSubmit = (event) => {
        localStorage.setItem('city', city);
        localStorage.setItem('car', JSON.stringify(car));
        localStorage.setItem('mileage', localStorage.getItem('mileage'));
        console.log(car);
        event.preventDefault();
        UserService.createCustomer({
            "email": email,
            "firstname": localStorage.firstName,
            "middlename": localStorage.middleName,
            "lastname": localStorage.lastName,
            "confidentiality": confidentiality === 'on',
            "lastMaintenanceDate":  localStorage.getItem('lastMaintenanceDate'),
            "gender": localStorage.gender,
            "startDate": startDate,
            "streetName": streetName,
            "streetNumber": streetNumber,
            "birthdate": localStorage.birthday,
            "additionalAddress": additionalAddress ?? '',
            "postCode": postCode,
            "city": city,
            "carId": car.id,
            "domainLanguage": window.location.hostname.split('.')[2],
            "mileage": localStorage.getItem('mileage'),
            "token": localStorage.getItem('token'),
        }, isAnonyme)
        .then((response) => {
            history.push({
                pathname: isAnonyme ? '/n/'+params.id+'/congratulation' : '/user-account-created',
                state: {
                    email: localStorage.email
                }
            })
        })
        .catch((error) => {
            console.log(error)
            history.push({
                pathname: isAnonyme ? '/n/'+params.id+'/already-associated' : '/user-car-associated',
                state: {
                    errorMessage:  t('The car you wish to add is already associated with another user')
                }
            })
        })
    }

    const handleChangeStreetName = (event) => {
        setStreetName(event);
        if(event === ''){
            setvalidStreetName(false);
        } else{
            setvalidStreetName(true);
        }

    }

    const handleChangeStreetNumber = (event) => {
        setstreetNumber(event);
        if(event === ''){
            setvalidStreetNumber(false);
        } else{
            setvalidStreetNumber(true);
        }

    }

    const handleChangeAdditionalAddress = (event) => {
        setAdditionalAddress(event);
        if(event === ''){
            setvalidStreetAdditionalAddress(false);
        } else{
            setvalidStreetAdditionalAddress(true);
        }

    }

    const handleChangePostCode = (event) => {
        setPostCode(event);
        if(event === ''){
            setvalidPostCode(false);
        } else{
            setvalidPostCode(true);
        }

    }

    const handleChangeCity = (event) => {
        console.log(city)
        setCity(event);
        if(event === ''){
            setvalidCity(false);
        } else{
            setvalidCity(true);
        }

    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                <h2 className="with-border">{t('Client Information')}</h2>
                                <p>{t("Fill in your current address")}</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label className="required form-label">{t('STREET NAME')}</label>
                                            {validStreetName && <span className="icon-check icon"></span>}
                                            <input type="text" name="streetName" value={streetName}
                                                   onChange={event => handleChangeStreetName(event.target.value)}
                                                   className="form-control" required/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="required form-label">{t('STREET NUMBER')}</label>
                                            {validStreetNumber && <span className="icon-check icon"></span>}
                                            <input type="text" name="streetNumber" value={streetNumber}
                                                   onChange={event => handleChangeStreetNumber(event.target.value)}
                                                   className="form-control" required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form-label">{t('ADDITIONAL ADDRESS')}</label>
                                            {validAdditionalAddress && <span className="icon-check icon"></span>}
                                            <input type="text" name="additionalAdrdess" value={additionalAddress}
                                                   onChange={event => handleChangeAdditionalAddress(event.target.value)}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="required form-label">{t('POST CODE')}</label>
                                            {validPostCode && <span className="icon-check icon"></span>}
                                            <input type="text" name="postCode" value={postCode}
                                                   onChange={event => handleChangePostCode(event.target.value)} className="form-control"
                                                   required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="required form-label">{t('CITY')}</label>
                                            {validCity && <span className="icon-check icon"></span>}
                                            <input type="text" name="city" value={city}
                                                   onChange={event => handleChangeCity(event.target.value)} className="form-control"
                                                   required/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="required-field mb-4"><span>*</span>{t('mandatory fields')
                                            }</div>
                                        </div>
                                        <div className="col-md-6">
                                            <button type="submit" className="button red mx-auto mr-md-0">{t('Next')}</button>
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserClientInfoNext
