import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import UserService from "../../services/UserService";

import logoNissanNow from "../../assets/images/logo.png";

import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const UserSetEmail = (props) => {
    const history = useHistory();
    let params = useParams();
    const isAnonyme = !!params.id;
    const [errorEmail, seterrorEmail] = useState(false);
    const [errorWrongEmail, setErrorWrongEmail] = useState(false);
    const [errorUserExist, seterrorUserExist] = useState(false);
    const [customer, setCustomer] = useState(JSON.parse(localStorage.getItem('customer')));
    const [validEmail, setvalidEmail] = useState(false) ;
    const [email, setEmail] = useState(customer ? customer.email :'');
    const [confirmationEmail, setconfirmationEmail] =  customer ? useState(customer.email) : useState('');
    const [car, setCar] = useState(localStorage.getItem('car'));

    if(!isAnonyme && localStorage.getItem('token') === null)
    {
        history.push('/login');
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem('email', email);

        if (email === confirmationEmail) {
            UserService.userExist({
                "email": email,
                "token": localStorage.getItem('token'),
            }).then((res) => {
                if (res.status == 200) {
                    localStorage.setItem('email', email);
                    localStorage.setItem('car', car);
                    history.push({
                        pathname: isAnonyme ? '/n/'+params.id+'/6' : '/user-client-info',
                        state: {
                            email: email,
                        }
                    })
                }
            })
            .catch((error) => {
                console.log((error));
                history.push({
                    pathname: isAnonyme ? '/n/'+params.id+'/email-already-use' : '/email-already-use',
                    state:
                        {
                            email: email
                        }
                })
            })
        }

    }

    const handleConfirmEmail = (event) => {
        let email_first = document.getElementsByName("email")[0].value;
        let confirmEmail = document.getElementsByName("confirmation-email")[0].value;
        setErrorWrongEmail(!validateEmail(event.target.value));
        if (email_first === confirmEmail) {
            seterrorEmail(false);
            setvalidEmail(true);
            setEmail(event.target.value)
            setconfirmationEmail(event.target.value)
        } else {
            seterrorEmail(true);
            setvalidEmail(false);
        }
    };

    const validateEmail = (string) =>{
        var reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return reg.test(string);
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="text-center board-info-title mb-4"></div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                    {!isAnonyme &&
                                        <div>
                                            <h2 className="with-border-top with-border-bottom">{t('Set Email')}</h2>
                                            <p>{t("What is your client's email address ?")}</p>
                                        </div>
                                    }
                                    {!isAnonyme &&
                                        <div className="alert alert-warning" role="alert">
                                            {t('Please double check the information with your client as it will be needed to activate the account.')}
                                        </div>
                                    }
                                    {isAnonyme &&
                                        <div>
                                            <h2 className="with-border-top with-border-bottom">{t('ANONYME SET EMAIL')}</h2>
                                            <p>{t("What is your email address ?")}</p>
                                        </div>
                                    }
                                    {isAnonyme &&
                                        <div className="alert alert-warning" role="alert">
                                            {t('Please double check the information')}
                                        </div>
                                    }
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="required form-label">{t('Email')}</label>
                                                    {validEmail && <span className="icon-check icon"></span>}
                                                    <input type="email" name="email" defaultValue={email}
                                                           onChange={event => handleConfirmEmail(event)} className="form-control"
                                                           required/>
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label className="required form-label">{t('Confirmation Email')}</label>
                                                    {validEmail && <span className="icon-check icon"></span>}
                                                    <input type="email" name="confirmation-email"
                                                           onChange={(event) => handleConfirmEmail(event)}
                                                           className="form-control" required/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                    <div className="form-group col-md-12">
                                                        {errorWrongEmail && <div><span className="error color-error">{t('Email must be valid')}</span></div>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12">
                                                        {errorEmail && <div><span className="error color-error">{t('Email must be the same')}</span></div>}
                                                    </div>
                                                </div>

                                                {errorUserExist && <div><span className="error color-error">{t('User already exist')}</span></div>}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="required-field mb-4"><span>*</span>{t('mandatory fields')}</div>
                                            </div>
                                            <div className="col-md-6">
                                                <button type="submit" className="button red mx-auto mr-md-0">{t('Next')}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserSetEmail
