import React from 'react';
import BasicPage from "../components/BasicPage";

import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";

const PasswordChoiceConfirmation = (props) => {
  const { t } = useTranslation();
    let redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_NL_NL;
    switch (history.state.state.language) {
        case 'nl-BE':
            redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_NL_BE;
            break;
        case 'fr-BE':
            redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_FR_BE;
            break;
        case 'fr-LU':
            redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_FR_LU;
            break;
    }
    
  return (
    <>
      <BasicPage external={true} icon="icon-check" title={t('Password set')} content={t('Your password has been successfully set and linked to your account.')} buttonText={t('Go to Nissan Now')} buttonLink={redirect} />
    </>
  );
}

export default PasswordChoiceConfirmation
