import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
      Accept:         'application/ld+json',
      'Content-Type': 'application/json',
  }
});

export default {
  getSalesperson: async function(data) {
    try {
      const response = await instance.get("/sales-persons", {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getDealerShipUsers: async function(data) {
    try {
      let path = "/dealership-users";
      if (data.dealer) {
        path = "/dealership-users/"+data.dealer;
      }
      const response = await instance.get(path, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getDirectoDealers: async function(data) {
    try {
      const response = await instance.get("/director/dealers", {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getDealers: async function(data) {
    try {
      const response = await instance.get("/dealers", {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  }
}
