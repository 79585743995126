import React from 'react';
import {useHistory} from 'react-router-dom';
import BasicPage from "../BasicPage";

import { useTranslation } from 'react-i18next';

const AccountCreated = (props) => {
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <>
      <BasicPage icon="icon-confirm-email" title={t('Account created')} content={t(`An email has been sent to {{email}} with a new link`,{email: history.location.state.email})} buttonText={t('Back to list')} buttonLink="/dealerboard" />
    </>
  );
}

export default AccountCreated