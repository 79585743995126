import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import logoNissanNow from "../../assets/images/logo.png";
import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const UserCarInformation = (props) => {
    let params = useParams();
    const isAnonyme = !!params.id;
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [car, setCar] = useState(JSON.parse(localStorage.getItem('car')));

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push({
            pathname: isAnonyme ? '/n/'+params.id+'/3' : '/user-car-found',
        })
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="text-center board-info-title mb-4">
                            <h1 className="text-center mb-5">{t('CAR FOUND')} <span className="icon-check icon"></span>
                            </h1>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                    <h2 className="with-border">{t('CAR INFORMATION')}</h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-3">
                                                    <div className="text-center">
                                                        <img src={localStorage.image} alt="Nissan Now" width="160"
                                                             className="mr-auto"/>
                                                        <p>{localStorage.model}</p>
                                                        <p>{car.licencePlate}</p>
                                                    </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="lighter-grey-text">{t('VIN')}</p>
                                                        <p>{car.vin}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="lighter-grey-text">{t('Engine')}</p>
                                                        <p>{car.engine ?? '---'}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="lighter-grey-text">{t('Grade')}</p>
                                                        <p>{car.grade ?? '---'}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="lighter-grey-text">{t('Fuel Type')}</p>
                                                        <p>{car.fuelTypeId ?? '---'}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="lighter-grey-text">{t('1st registration')}</p>
                                                        <p>{car.firstRegistrationDate ? moment(car.firstRegistrationDate).format('DD/MM/YYYY') : 'Unknown'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="lighter-grey-text">{t('Last Maintenance')}:</p>
                                                    <p>{car.lastMaintenanceUpdatedDate ? moment(car.lastMaintenanceUpdatedDate).format('DD/MM/YYYY') : 'Unknown'}</p>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="lighter-grey-text mt-4">{t('Mileage')}</p>
                                                    <p className="mt-5">{car.currentMileage} KM</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button type="submit" className="button red mx-auto mr-md-0">{t('Next')}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserCarInformation
