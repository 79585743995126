import React from 'react';
import {useHistory} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";

import logoNissanNow from "../../assets/images/logo.png";
import carteGrise from "../../assets/images/carte-grise.jpeg";

import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const ExampleIdentificationNumber = (props) => {
    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push('/user-add-car');
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />

            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h2 className="with-border">{t('Example of identification number')}</h2>
                                                <p>The identification number (VIN) is located int the "E" zone of your vehicle
                                                    registration document. It usually has 17 characters for recent vehicles.</p>
                                                <p>
                                                    For old cars you can find it at the lower-left corner of your windshield
                                                </p>
                                                <button type="submit" className="button back red mx-auto mr-md-0">{t('Go back')}</button>
                                            </div>
                                            <div className="col-md-6 text-center">
                                              <img src={carteGrise} alt="Nissan Now" width="250" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default ExampleIdentificationNumber
