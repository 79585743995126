import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import validator from 'validator' 
import UserService from "../../services/UserService";
import logoNissanNow from "../../assets/images/logo.png";
import {useTranslation} from 'react-i18next';


const UserContactPreferences = (props) => {
    const history = useHistory();
    const [checkBoxSmsPersonalized, setCheckBoxSmsPersonalized] = useState('');
    const [checkBoxEmailPersonalized, setCheckBoxEmailPersonalized] = useState(false);
    const [checkBoxTest, setCheckBoxTest] = useState(false);
    const [checkBoxSmsReminders, setCheckBoxSmsReminders] = useState('');
    const [checkBoxEmailReminders, setCheckBoxEmailReminders] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [validPhoneNumber, setvalidPhoneNumber] = useState(false);
    const [user_id, setuser_id] = useState(JSON.parse(localStorage.getItem('user_id')));

    const handleSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem('phoneNumber', phoneNumber);
        localStorage.setItem('smsReminders', checkBoxSmsReminders);
        localStorage.setItem('emailReminders', checkBoxEmailReminders);
        localStorage.setItem('smsPersonalized', checkBoxSmsPersonalized);
        localStorage.setItem('emailPersonalized', checkBoxEmailPersonalized);
        localStorage.setItem('phoneNumber', phoneNumber);

        UserService.updateOptin({
            "user_id": user_id.id,
            "smsReminders": checkBoxSmsReminders,
            "emailReminders": checkBoxEmailReminders,
            "smsPersonalized": checkBoxSmsPersonalized,
            "emailPersonalized": checkBoxEmailPersonalized,
            "phoneNumber": phoneNumber
          })
          .then((res) => {
            history.push({
              pathname: '/user-congratulations'
            })
          })
          .catch((error) => {
            console.log(error)
          })
    }

    const handleChangePhoneNumber = (event) => {
        setphoneNumber(event.replace(/ /g, ''));
        if(validator.isMobilePhone(event) === true){
            setvalidPhoneNumber(true);
        } else{
            setvalidPhoneNumber(false);
        }
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <header className="header py-2">
                <div className="container d-flex align-items-center">
                    <img src={logoNissanNow} alt="Nissan Now" width="160" className="mr-auto logo-width"/>
                </div>
            </header>

            <div className="bar-step">
                <a href="/customerboard" className="button white back align-self-center">{t('Back to client list')}</a>

                <a href="/user-set-email" className="step1">
                    <span className="icon-warranty icon"></span>
                    <span className="d-none d-md-block text">{t('SET YOUR PASSWORD')}</span>
                </a>
                <a href="/user-client-info" className="active step2">
                    <span className="icon-inbox icon"></span>
                    <span className="d-none d-md-block text text-dealer-favorite">{t('YOUR CONTACT PREFERENCES')}</span>
                </a>
            </div>
            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                <h2 className="with-border">{t('How would you prefer to receive')}</h2>
                                <p>{t("Service reminders")}</p>
                                <p>{t("Get reminder of your maintenance dates")}</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="form-group col-md-2 checkbox-custom">
                                            <input type="checkbox" id="checkBoxSmsReminders" name="checkBoxSmsReminders" checked={checkBoxSmsReminders}
                                                   className="form-control"
                                                   onChange={event => setCheckBoxSmsReminders(event.target.checked)}/>
                                            <label htmlFor="checkBoxSmsReminders" className="form-label multiline">{t('SMS')}</label>
                                        </div>
                                        <div className="form-group col-md-2 checkbox-custom">
                                            <input type="checkbox" id="checkBoxEmailReminders" name="checkBoxEmailReminders" checked={checkBoxEmailReminders}
                                                   className="form-control"
                                                   onChange={event => setCheckBoxEmailReminders(event.target.checked)}/>
                                            <label htmlFor="checkBoxEmailReminders" className="form-label multiline">{t('EMAIL')}</label>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-12">
                                            <p>{t("Personalized Offers")}</p>
                                            <p>{t("Get exclusive & personalized offers")}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-2 checkbox-custom">
                                            <input type="checkbox" id="checkBoxSmsPersonalized" name="checkBoxSmsPersonalized" checked={checkBoxSmsPersonalized}
                                                   className="form-control"
                                                   onChange={event => setCheckBoxSmsPersonalized(event.target.checked)}/>
                                            <label htmlFor="checkBoxSmsPersonalized" className="form-label multiline">{t('SMS')}</label>
                                        </div>
                                        <div className="form-group col-md-2 checkbox-custom">
                                            <input type="checkbox" id="checkBoxEmailPersonalized" name="checkBoxEmailPersonalized" checked={checkBoxEmailPersonalized}
                                                   className="form-control"
                                                   onChange={event => setCheckBoxEmailPersonalized(event.target.checked)}/>
                                            <label htmlFor="checkBoxEmailPersonalized" className="form-label multiline">{t('EMAIL')}</label>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="form-group col-md-4">
                                            <label className="required form-label">{t('PHONE NUMBER')}</label>
                                            {validPhoneNumber && <span className="icon-check icon"></span>}
                                            <input type="text" placeholder="31250555111" name="phoneNumber" value={phoneNumber}
                                                   onChange={event => handleChangePhoneNumber(event.target.value)} className="form-control"
                                                   required/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button type="submit" className="button red mx-auto mr-md-0">{t('Finish')}</button>
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserContactPreferences
