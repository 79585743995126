import React from 'react';
import {useHistory} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";

import logoNissanNow from "../../assets/images/logo.png";

import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const UserAddCar = (props) => {
    const history = useHistory();

    const handleSubmit = (event) => {
        history.push({
            pathname: '/user-add-car',
        })
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container" style={{ textAlign: "center" }}>
                <main>
                    <OnboardLayout>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                <h2 className="text-center mb-5"><span className="icon-close icon icon-with-circle"></span></h2>
                                <h2 className="with-border d-none">{t('Email Already use ')}</h2>
                                <p className="mb-5">{t('The email you wish to add is already associated with another user. Please try again with another email. ')}
                                </p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button type="submit" className="button back red mx-auto">{t(' Go Back')}</button>
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserAddCar
