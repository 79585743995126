import React from 'react';
import {useHistory} from 'react-router-dom';
import BasicPage from "../components/BasicPage";

const NewLinkSent = (props) => {
  const history = useHistory();

  return (
    <>
      <BasicPage icon="icon-confirm-email" title="New link sent" content={`An email has been sent to ${history.location.state.email} with a new link.`} buttonText="Back to list" buttonLink="/dealerboard" />
    </>
  );
}

export default NewLinkSent