import React, { useState, useEffect } from 'react';
import UserManage from "../components/Modale/UserManage";
import DealerService from "../services/DealerService";
import Layout from "../components/Layout";
import { useTranslation } from 'react-i18next';
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom';

const Dealerboard = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(true);

  // Three modal types = "new" or "resend" or "revoke"
  const [modalType, setModalType] = useState('');

  /* Datas from API CAll */
  const [datas, setDatas] = useState([]);
  const [dealerList, setDealerList] = useState([]);

  /* Local data used for display */
  const [tableDatas, setTableDatas] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  /* Filters */
  const [searchFilter, setSearchFilter] = useState('');
  const [dealerFilter, setDealerFilter] = useState('');
  const [activeFilter, setActiveFilter] = useState('');
  const [isSuperDealer, setIsSuperDealer] = useState(false);
  const history = useHistory();

  useEffect(() => {
    /* Verify roles */
    let role = localStorage.getItem('role')
    if(role === null)  {
      localStorage.clear();
      history.push('/login');
    } else if (role && !(role.includes('ROLE_ADMIN') || role.includes('ROLE_SUPER_DEALER'))) {
      history.push('/login');
    }

    if (role.includes('ROLE_SUPER_DEALER')) {
      setIsSuperDealer(true);
    }

    /* Initial API Calls */
    DealerService.getSalesperson({ token: localStorage.getItem('token') })
    .then((response) => {
      setDatas(response.data.salesPersons);
      setTableDatas(response.data.salesPersons);
      setLoader(false);
    })
    .catch((error) => {
      console.log(error);
      if (error.toString().includes("401")) {
        localStorage.clear();
        history.push('/login');
      }
    });

    DealerService.getDealers({ token: localStorage.getItem('token') })
    .then((response) => {
      setDealerList(response.data['hydra:member']);
    })
    .catch((error) => {
      console.log(error);
      if (error.toString().includes("401")) {
        localStorage.clear();
        history.push('/login');
      }
    });
    /* /Initial API Calls */
  },[])

  /* Filter methods  */
  const searchFilterChange = (e) => {
    setSearchFilter(e.target.value);
    globalFilter(e.target.value, dealerFilter, activeFilter);
  }

  const dealershipFilterChange = (e) => {
    if(e.target.value==="all"){
      setDealerFilter(null);
      globalFilter(searchFilter, null, activeFilter);
    }else{
      setDealerFilter(e.target.value);
      globalFilter(searchFilter, e.target.value, activeFilter);
    }
  }

  const activeFilterChange = (e) => {
    if(e.target.value==="all"){
      setDealerFilter(null);
      globalFilter(searchFilter, dealerFilter, null);
    }else {
      setActiveFilter(e.target.value);
      globalFilter(searchFilter, dealerFilter, e.target.value);
    }
  }

  /*
   * Filtering with all the filters
   */
  const globalFilter = (searchFilterParams="", dealerFilterParams="", activeFilterParams="") => {
    let generalFilter = data => {
      if(searchFilterParams && (!data.email.toLowerCase().includes(searchFilterParams.toLowerCase()) && !(data.first_name+' '+data.last_name).toLowerCase().includes(searchFilterParams.toLowerCase()))) {
        return false;
      }

      if(dealerFilterParams && data.dealer_id !== dealerFilterParams) {
        return false;
      }

      let nicename = data.old_dealer != 1 ? "Active" : "Inactive";

      if(activeFilterParams && nicename !== activeFilterParams) return false;

      return true;
    }
    setTableDatas(datas.filter(generalFilter));
  }
  /* /Filter methods */

  /* Display methods
   * status takes old_dealer value from api
   * selectedUser contains all the datas of the current user. It's given to the openModal method when needed
   */

  const actions = (status,selectedUser) => {
    if (status !== 1) {
      return (
          <div>
            <button className="btn dark-red text-decoration-none" onClick={() => openModal("revoke", selectedUser)}><span className="icon-2-delete fs-20"></span></button>
            {selectedUser && selectedUser.dealer_id && isSuperDealer &&
                <a href={'javascript:void(0);'} className="dark-red d-block px-3"
                   onClick={event => redirectToTimeline(selectedUser)}>{t('See the customerboard')}</a>
            }
          </div>
      );
    } else {
      return (<span onClick={() => openModal("resend", selectedUser)} className="dark-red text-decoration-none dark-grey cursor-pointer"><span className="icon-inbox align-middle"></span> {t('Re-send activation link')}</span>);
    }
  }

  const redirectToTimeline = (selectedUser) => {
    localStorage.setItem('selectedDealer', JSON.stringify(selectedUser));
    localStorage.setItem('previousPage', 'dealerboard');
    history.push({
      pathname: "/customerboard",
      state: {
        selectedUser: localStorage.setItem('selectedDealer', JSON.stringify(selectedUser))
      }
    })
  }

  const dealershipFilterOptions = () => {
    return (
      dealerList.length > 0 &&
      dealerList
      .sort((a, b) =>{
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      })
      .map((data) => {
          return(
            <option value={data.id} key={data.id}>{data.name}</option>
          )}
      )
    )
  }
  /* Display methods */

  /* Modal methods */

    /*
     * modalType = "new", 'resend' or "revoke"
     */
    const openModal = (modalType, user={}) => {
      setShowModal(true);
      setModalType(modalType);
      setSelectedUser(user);
    }

    const handleClose = () => {
      setShowModal(false);
      setSelectedUser({});
    }

    const showContent = (loader) => {
      if(loader == true)
      {
        return (
          <div style={{ textAlign: "center" }}>
            <Loader
              type="TailSpin"
              color="#C0092D"
              height={100}
              width={100}
            /> 
          </div>
      )
      } else {
        return(
        <table className="table table-striped table-align-middle fs-13">
            <thead>
            <tr className="font-weight-bold">
              <th scope="col">{t('Name')}</th>
              <th scope="col">Email</th>
              <th scope="col">{t('Dealership')}</th>
              <th scope="col">{t('Creation Date')}</th>
              <th scope="col">{t('Last Login')}</th>
              <th scope="col">{t('Status')}</th>
              <th scope="col">{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {
              tableDatas.map((data, index) => {
              return(
                <tr key={index}>
                  <td>{data.first_name} {data.last_name}</td>
                  <td>{data.email}</td>
                  <td>{(dealerList.length > 0 && dealerList.filter(dealerItem => data.dealer_id === dealerItem.id)[0]) && dealerList.filter(dealerItem => data.dealer_id === dealerItem.id)[0].name}</td>
                  <td>{data.subscription_date}</td>
                  <td>{data.connection_date}</td>
                  <td>{data.old_dealer !== 1 && data.validation_date !== null ? t('Active') : t('Inactive')}</td>
                  <td>{actions(data.old_dealer, data)}</td>
                </tr>
              )})
            }
          </tbody>
        </table>
        )
      }
    }
  /* /Modal methods */

  const { t } = useTranslation();

  return (
    <Layout>
      <div className="white-container">
        <h2 className="with-border text-uppercase">{t('Salesperson management')}</h2>
        <div className="row mb-4">
          <div className="col-md-6 col-lg-3">
            <div className="input-with-icon">
              <input type="text" value={searchFilter} onChange={(e) => searchFilterChange(e)} className="form-control fs-13" placeholder={t('Search')} />
              <span className="icon-search icon"></span>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="select">
              <select defaultValue="" onChange={(event) => dealershipFilterChange(event)}  className="form-control fs-13">
                <option value="all">{t('All concessions')}</option>
                <option disabled value="">{t('Dealership')}</option>
                {dealershipFilterOptions()}
              </select>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="select">
              <select defaultValue="" onChange={(event) => activeFilterChange(event)}  className="form-control fs-13">
                <option disabled value="" >{t('Activation status')}</option>
                <option value="all">{t('All')}</option>
                <option value="Active">{t('Active')}</option>
                <option value="Inactive">{t('Inactive')}</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <button className="button red with-icon fs-13" onClick={() => openModal("new")}><span className="icon-profile icon-profile-new icon"></span><span className="pl-2">{t('Add a new salerperson')}</span></button>
          </div>
          
        </div>
          {
            showContent(loader)
          }
      </div>

      { showModal &&
      <UserManage dealerList={dealerList} showModal={showModal} handleClose={() => handleClose()} modalType={modalType} selectedUser={selectedUser} />
      }

    </Layout>
  );
}


export default Dealerboard
