import React from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import logoNissanNow from "../../assets/images/logo.png";
import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const UserCarAssociated = (props) => {
    const history = useHistory();
    let params = useParams();
    const isAnonyme = !!params.id;
    const errorMessage = history.location.state.errorMessage

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push({
            pathname: isAnonyme ? '/n/'+params.id : '/user-add-car',
        })
    }

    if(localStorage.getItem('token') === null && !isAnonyme){
        history.push('/login');
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                    <h2 className="text-center mb-5"><span className="icon-close icon"></span></h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <p className="mb-5">{errorMessage}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button type="submit" className="button back red mx-auto">{t('GO BACK')}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserCarAssociated
