import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import UserService from "../../services/UserService";

import { useTranslation } from 'react-i18next';

const ForgotPassword = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [errorEmail, seterrorEmail] = useState(false);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    UserService.sendForgortPasswordEmail({
      "email": email
    })
    .then((res) => {
      history.push({
        pathname: '/reset-link-sent',
        state: { email: email }
      })
    })
    .catch((error) => {
      console.log(error)
      seterrorEmail(true);
    })
  }

  const { t } = useTranslation();

  return (
    <BasicLayout>
      <h2 className="with-border">{t('Forgot Password')}</h2>
      <p>{t('Please enter your login email address to see options for regaining access to your account')}.</p>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="required form-label">Email</label>
          <div className="form-group">
            <input type="email" name="name" value={email} onChange={handleChangeEmail} className="form-control" />
          </div>
          {errorEmail && <div><span className="error color-error">User don't exist</span></div>}
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="required-field mb-4"><span>*</span>{t('mandatory fields')}</div>
          </div>
          <div className="col-md-6">
            <button type="submit" className="button red mx-auto mr-md-0">{t('Submit')}</button>
          </div>
        </div>
      </form>
    </BasicLayout>
  );
}


export default ForgotPassword
