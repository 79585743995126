import axios from 'axios';
import moment from 'moment';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
      Accept:         'application/ld+json',
      'Content-Type': 'application/json',
  }
});

export default {
  // Login
  login: async function(data) {
      
      const response = await instance.post('/authenticate', 
        {
          username : data.username,
          password : data.password 
      });
        return response;
  },

  // Revoke
  revoke: async function(data) {
    try {
      const response =  instance.post('/revoke-dealer/',
          {
            "id": data.id
          },
          {
            headers: {
                'Authorization': data.token,
            }
          }
      );
      return response;
    } catch (error){
      throw error;
    }
  },

  updatePassword: async function(data) {
    try {
      const response =  instance.post('/update-password',
          {
            "uuid": data.uuid,
            "password": data.password,
            "validationDate": moment().format()
          },
          {
            headers: {
            }
          }
      );
      return response;
    } catch (error){
      throw error;
    }
  },

  updateOptin: async function(data) {
    try {
      const response =  instance.post('/update-optin',
          {
            "user_id": data.user_id,
            "emailPersonalized": data.emailPersonalized,
            "smsPersonalized": data.smsPersonalized,
            "emailReminders": data.emailReminders,
            "smsReminders": data.smsReminders,
            "phoneNumber": data.phoneNumber,
          },
          {
            headers: {
            }
          }
      );
      return response;
    } catch (error){
      throw error;
    }
  },

  // Create User from admin
  createUser: async function(data) {
    try {
      const response = instance.post('/add-user/',
          {
            "firstName": data.first_name,
            "lastName": data.last_name,
            "gender": 0,
            "birthDate": "",
            "phone": "",
            "email": data.email,
            "password": "Mindoza2021!",
            "optin": false,
            "language": data.lang,
            "status": 1,
            "subscriptionDate": moment().format(),
            "validationDate": "",
            "lastUpdateDate": "",
            "deletionDate": "",
            "newPasswordRequestAt": "",
            "newPasswordRequestToken": "",
            "particleName": "",
            "country": data.country,
            "reminderChannelNotification": false,
            "reminderChannelSMS": false,
            "reminderChannelEmail": false,
            "offerChannelNotification": false,
            "offerChannelSMS": false,
            "offerChannelEmail": false,
            "welcomeTimeline": false,
            "welcomeRewards": false,
            "dealer": data.dealer_id,
            "source": "",
            "medium": "",
            "campaign": "",
            "streetName": "",
            "houseNumber": "",
            "houseNumberExt": "",
            "postalCode": "",
            "city": "",
            "roles":[data.role],
            "boardingFinishDate": "",
            "scheduledDeletionDate": "",
            "countOfUserReward": 0,
            "countOfEmailToRead": 0,
            "exportedToLsm": false
        },
        {
          headers: {
            'Authorization': data.token,
          }
        }
      );

      return response;
    } catch (error){
      throw error;
    }
  },

  sendConfirmDealerEmail: async function(data) {
    try {
        const response = await instance.post("/send-confirm-dealer",
          {
              "email": data.email,
              "lang": data.lang,
              "old_email": data.old_email,
              "first_name": data.first_name,
              "last_name": data.last_name,
              "dealer_id": data.dealer_id,
          },
          {

              headers: {
                  'Authorization': data.token,
              }
          }
        );
        return response;
    } catch (error){
        throw error;
    }
  },

  sendConfirmCustomerEmail: async function(data) {
    try {
        const response = await instance.post("/send-confirm-customer",
          {
              "email": data.email,
              "lang": data.lang
          },
          {

              headers: {
                  'Authorization': data.token,
              }
          }
        );
        return response;
    } catch (error){
        throw error;
    }
  },

  sendForgortPasswordEmail: async function(data) {
    try {
        const response =  instance.post("/email-forgot-password-dealer",
          {
              "email": data.email
          },
        );
        return response;
    } catch (error){
        throw error;
    }
  },

  resetPassword: async function(data) {
    try {
      const response =  instance.post('/reset-password',
          {
            "uuid": data.uuid,
            "password": data.password
          },
      );
      return response;
    } catch (error){
      throw error;
    }
  },

  userRoles: async function(data) {
    try {
        const response = instance.post("/user-roles",
          {
              "email": data.email
          },
          {

              headers: {
                  'Authorization': data.token,
              }
          }
        );
        return response;
    } catch (error){
        throw error;
    }
  },

  getCar: async function(data) {
    try {
      const response = await instance.get("/customer-infos-by-vin/"+data.vin);
      return response;
    } catch (error){
      throw error;
    }
  },

  getCarByToken: async function(data) {
    try {
      const response = await instance.get("/customer-infos-by-token/"+data.id);
      return response;
    } catch (error){
      throw error;
    }
  },

  getOnboarders: async function(data) {
    try {
      const response = await instance.get("/onboarders", {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getDealerships: async function(data) {
    try {
      const response = await instance.get("/dealerships", {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getUserById: async function(data) {
    try {
      const response = await instance.get("/user/"+data.id, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },


  getCarById: async function(data) {
    try {
      const response = await instance.get("/car/id/"+data.id, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getTimeline: async function(data) {
    try {
      const response = await instance.get("/timeline/car/"+data.carId, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getDealerTimelines: async function(data) {
    try {
      const response = await instance.get("/dealer-timelines", {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getDirectorTimelines: async function(data) {
    try {
      const response = await instance.get("/director-timelines", {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  welcomeTimeline: async function(data) {
    try {
        const response = instance.post("/welcome",
          {
              "type": data.type
          },
          {
              headers: {
                  'Authorization': data.token,
              }
          }
        );
        return response;
    } catch (error){
        throw error;
    }
  },

  reminderSeend: async function(data) {
    try {
      const response = await instance.get("/timeline/event/seen/"+data.eventId, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getMeUserId: async function(data) {
    try {
      const response = await instance.get("/meuser/"+data.id, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  userEvents: async function(data) {
    try {
      const response = await instance.get(data.url, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  userDealer: async function(data) {
    try {
      const response = await instance.get(data.url, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  userCarTimeline: async function(data) {
    try {
      const response = await instance.get(data.url, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  createCustomer: async function(data, isAnonyme) {

      let config = {};
      if (!isAnonyme) {
          config = {
              headers: {
                  'Authorization': data.token,
              }
          };
      }
      try {
        const response = await instance.post("/dealer/add-user",
          {
              "email": data.email,
              "firstname": data.firstname,
              "middlename": data.middlename,
              "lastname": data.lastname,
              "confidentiality": data.confidentiality,
              "lastMaintenanceDate": data.lastMaintenanceDate,
              "gender": data.gender,
              "startDate": data.startDate,
              "birthdate": data.birthdate,
              "streetName": data.streetName,
              "streetNumber": data.streetNumber,
              "additionalAddress": data.additionalAddress ?? '',
              "postCode": data.postCode,
              "carId": data.carId,
              "mileage": data.mileage,
              "city": data.city,
              "domainLanguage": data.domainLanguage ?? null,
              "token": localStorage.getItem('token'),
          }, config);
        return response;
    } catch (error){
        console.log(error)
        throw error;
    }
  },

  userExist: async function(data) {
    try {
        const response = await instance.post("/user-exist",
          {
              "email": data.email,
          }
        );
        return response;
    } catch (error){
        throw error;
    }
  },
}
