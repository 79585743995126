import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import translationEn from "../locales/en/en.json"
import translationFr from "../locales/fr/fr.json"
import translationNl from "../locales/nl/nl.json"

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
      translation: translationEn
  },
  fr: {
    translation: translationFr
  },
  nl: {
    translation: translationNl
  }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en", 

    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;