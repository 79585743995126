import React, { useState } from 'react';
import {useHistory} from "react-router-dom";
import {Modal} from "react-bootstrap";
import UserService from "../../services/UserService";
import { useTranslation } from 'react-i18next';

const UserManage = (props) => {
  let [firstName, setFirstName] = useState(props.selectedUser ? props.selectedUser.first_name : '');
  let [lastName, setLastName] = useState(props.selectedUser ? props.selectedUser.last_name : '');
  let [email, setEmail] = useState(props.selectedUser ? props.selectedUser.email : '');
  let [oldEmail, setoldEmail] = useState(props.selectedUser ? props.selectedUser.email : '');
  let [country, setCountry] = useState(props.selectedUser ? props.selectedUser.country : '');
  let [language, setLanguage] = useState(props.selectedUser ? props.selectedUser.language : '');
  let [dealership, setDealership] = useState(props.selectedUser ? props.selectedUser.dealer_id : '');
  let [role, setRole] = useState('ROLE_DEALER');
  let [dealershipList, setDealershipList] = useState(props.dealerList);
  const [emailError, setEmailError] = useState(false);

  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    if(props.modalType === "new") {
      UserService.createUser({
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "token": localStorage.getItem('token'),
        "dealer_id": dealership,
        "lang": language,
        "role": role,
        "country": country
      })
      .then((resUser) => {
        if(resUser.status == 200){
          UserService.sendConfirmDealerEmail({
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "old_email": email,
            "token": localStorage.getItem('token'),
            "dealer_id": dealership,
            "lang": language
          }).then((resEmail) => {
            if(resEmail.status == 200){
              history.push({
                pathname: '/account-created',
                state: { email: email }
              })
            }
          }).catch((error) => {
            console.log(error);  
          })
        }
      })
      .catch((error) => {
        console.log(error);  
      })
    } else if (props.modalType === "resend"){
      UserService.sendConfirmDealerEmail({
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "old_email": oldEmail,
        "token": localStorage.getItem('token'),
        "dealer_id": dealership,
        "lang": language
      }).then((resUserResend) => {
        if(resUserResend.status == 200){
          history.push({
            pathname: '/reset-link-sent',
            state: { email: email }
          })
        }
      }).catch((error) => {
        console.log(error)
      })
    } else if (props.modalType === "revoke") {
      /* REVOKE LINK */
      UserService.revoke({
        "token": localStorage.getItem('token'),
        "id": props.selectedUser.id
      })
      .then((resUserRevoke) => {
        if(resUserRevoke.status == 200){
          history.push({
            pathname: '/account-deleted',
            state: { email: email }
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
    } 
  }

  /* Country display method */
  const dealershipCountryOptions = () => {
    const unique = [...new Set(props.dealerList.map(dealer => dealer.country))];
    return (
      unique
      .sort((a, b) =>{
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
      })
      .map((data) => {
        let country;
        switch(data) {
          case "BE":
            country = "Belgium";
          break;
          case "NL":
            country = "Netherlands"
          break;
          case "LU":
            country = "Luxembourg"
          break;
        }
        
        return(
          <option value={data} key={data}>{t(country)}</option>
        )}
      )
    )
  }

    /* Langue display method */
    const dealershipLanguageOptions = () => {
      if(props.modalType === 'revoke') {
        return (
          <select disabled name ="language" className="form-control"  value={language} onChange={(event) => setLanguage(event.target.value)}>
              <option value="fr-BE">{t('French')}</option>
              <option value="nl-NL">{t('Dutch')}</option>
          </select>
        )
      } else if(country == 'NL'){
          language = 'nl-NL'
          return (
            <select name ="language" className="form-control"  value={language}>
                <option value="nl-NL">{t('Dutch')}</option>
            </select>
          )
          
      } else if(country == 'LU'){
        language = 'fr-LU'
          return (
            <select name ="language" className="form-control"  value={language}>
                <option value="fr-LU">{t('French')}</option>
            </select>
          )
      } else {
          return (
            <select name ="language" className="form-control"  value={language} onChange={(event) => setLanguage(event.target.value)}>
                <option disabled selected value>{t('Select a language')}</option>
                <option value="fr-BE">{t('French')}</option>
                <option value="nl-BE">{t('Dutch')}</option>
            </select>
          )
      }
    }

  /* Filter on country change */
  const filterOnChange = (e) => {
    setCountry(e.target.value);

    let filteredDealers = props.dealerList
    .filter(dealer => dealer.country === e.target.value)
    .sort((a, b) =>{
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
      })
    // je donne la liste des concessionnaires triée à dealershiplist
    setDealershipList(filteredDealers);
    // je selectionne le nom du premier concessionnaire de la liste [0]
    setDealership(filteredDealers[0].id);
  }

  const dealershipOptions = () => {
    return (
      dealershipList.map((data) => {
        return(
          <option value={data.id} key={data.id}>{data.name}</option>
        )}
      )
    )
  }

  const closeModale = () => {
    props.handleClose();
    /* Reset all state on modale close */
    setFirstName('');
    setLastName('');
    setEmail('');
    setCountry('');
    setLanguage('');
    setDealership('');
  }

  const { t } = useTranslation();
  
  /* Conditional display depending of modal Type */
  const displayTitle = () => {
    if (props.modalType === "new") return t('Add a new salesperson');
    else if (props.modalType === "resend") return t('Re-send activation link');
    else return t('Revoke access to')
  }

  const displayButton = () => {
    if (props.modalType === "new" || props.modalType === "resend") return t('Send activation link');
    else return t('Revoke access')
  }

  /* /Conditional display depending of modal Type */

  return (
    <>
    <Modal size="sm" show={props.showModal} centered onHide={() => closeModale()} animation={false} >
        <Modal.Header className="justify-content-center">
          <h2 className="modal-title flex-grow-1">{displayTitle()}</h2>
          <button type="button" className="close" onClick={() => closeModale()}>
            <span aria-hidden="true">
              <span className="icon-close"></span>
            </span>
          </button>
        </Modal.Header>
        <Modal.Body className="px-4 pt-4 pb-4 " >
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="first-name">
                {t('First Name')}
              </label>
              <input disabled={props.modalType === 'revoke' ? "disabled" : ""} id="first-name" className="form-control" type="text" name="firstname" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">
                {t('Name')}
              </label>
              <input disabled={props.modalType === 'revoke' ? "disabled" : ""} id="last-name" className="form-control" type="text" name="lastname" value={lastName} onChange={(event) => setLastName(event.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="email">
                Email
              </label>
              <input disabled={props.modalType === 'revoke' ? "disabled" : ""} id="email" className={`form-control ${emailError ? "error" : ""}`} type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} />
              {/* {emailError && <div><span className="color-error">{t('User already exist')}.</span></div>} */}
            </div>
            <div className="form-group">
              <label htmlFor="country">
                {t('Country')}
              </label>
                <select disabled={props.modalType === 'revoke' ? "disabled" : ""} className="form-control" value={country} onChange={(e) => filterOnChange(e)}>
                  <option disabled selected value>{t('Select a country')}</option>
                  {dealershipCountryOptions()}
                </select>

            </div>
            <div className="form-group">
              <label htmlFor="language">
                {t('Language')}
              </label>
                {dealershipLanguageOptions()}
            </div>
            <div className="form-group">
              <label htmlFor="dealership">
                {t('Dealership')}
              </label>
              <select disabled={props.modalType === 'revoke' ? "disabled" : ""} className="form-control" value={dealership} onChange={(e) => setDealership(e.target.value)}>
              <option disabled selected value>{t('Select a dealership')}</option>
              <option selected value=''>------------------------</option>
                {
                  dealershipOptions()
                }
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="dealership">
                {t('Role')}
              </label>
              <select  className="form-control" value={role} onChange={(e) => setRole(e.target.value)}>
              <option disabled selected value>{t('Select a role')}</option>
                <option value="ROLE_DEALER" >Rôle dealer</option>
                <option value="ROLE_ADMIN">Rôle admin</option>
              </select>
            </div>
            <div className="text-center">
              <input type="submit" value={displayButton()} className="button_modal red without-icon" />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}


export default UserManage