import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";

import logoNissanNow from "../../assets/images/logo.png";

import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const UserAccountCreated = (props) => {
    const history = useHistory();
    let params = useParams();
    const { i18n } = useTranslation();
    const [isAnonyme, setIsAnonyme] = useState(!!params.id);
    const [token, setToken] = useState(localStorage.getItem('bearer') ? localStorage.getItem('bearer') : '');
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
    
    const handleSubmit = (event) => {
        event.preventDefault();
        localStorage.removeItem('middleName');
        localStorage.removeItem('gender');
        localStorage.removeItem('lastName');
        localStorage.removeItem('streetName');
        localStorage.removeItem('startDate');
        localStorage.removeItem('additionalAdrdess');
        localStorage.removeItem('car');
        localStorage.removeItem('city');
        localStorage.removeItem('confidentiality');
        localStorage.removeItem('email');
        localStorage.removeItem('firstName');
        localStorage.removeItem('gender');
        localStorage.removeItem('image');
        localStorage.removeItem('mileage');
        localStorage.removeItem('model');
        localStorage.removeItem('postCode');
        localStorage.removeItem('startDate');
        localStorage.removeItem('streetNumber');
        localStorage.removeItem('vin');

        if (isAnonyme) {
            let redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_NL_NL;
            switch (i18n.language) {
                case 'nl':
                    redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_NL_BE;
                    break;
                case 'fr':
                    redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_FR_BE;
                    break;
                case 'lu':
                    redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_FR_LU;
                    break;
            }
            history.push(redirect);
        }

        history.push( '/customerboard');
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />

            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                    <form onSubmit={handleSubmit}>
                                        {isAnonyme &&
                                            <div className="row text-center">
                                                <div className="col-md-12 board-end-info">
                                                    <div className="icon-check icon mb-4"></div>
                                                    <h1>{t('Account Created')}</h1>
                                                    <p>{isAnonyme ? t('An email has been sent to your account') : t('An email has been sent to your client')} <span
                                                        className="text-underline">{history.location.state.email}</span></p>
                                                    <p>{isAnonyme ? t('init your password') : t('Please take a few minutes to explain the last step to activate their account and how the loyalty program works')}.</p>
                                                </div>
                                            </div>
                                        }
                                        {!isAnonyme &&
                                            <div className="row text-center">
                                                <div className="col-md-12 board-end-info">
                                                    <div className="icon-check icon mb-4"></div>
                                                    <h1>{t('Account Created')}</h1>
                                                    <p>{t('An email has been sent to your client')} <span
                                                        className="text-underline">{history.location.state.email}</span></p>
                                                    <p>{t('Please take a few minutes to explain the last step to activate their account and how the loyalty program works')}.</p>
                                                </div>
                                                <div className="col-md-12 mt-2">
                                                    <button type="submit"
                                                            className="button back red">{t('Back to Clients list')}</button>
                                                </div>
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserAccountCreated
