import React from "react"
import PropTypes from "prop-types"
import logoNissanNow from "../assets/images/logo.png";

const BasicLayout = ({ children }) => {
  return (
    <>
      <div className="wrapper pt-0">

        <div className="container">
          <main>
            <div className="text-center mb-4">
              <img src={logoNissanNow} alt="Nissan Now" width="240" />
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-10 col-lg-8 col-xl-7">

                <div className="white-container">
                  {children}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )

}

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BasicLayout
