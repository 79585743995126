import React, {useEffect, useState} from "react"
import {useTranslation} from 'react-i18next';
import UserService from "../../services/UserService";

const TropheeNameBlock = ({currentUser, userGender}) => {
  const {t} = useTranslation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('selectedUser')));
  const [selectUser, setselectUser] = useState('');

  useEffect(() => {
    setselectUser(currentUser);
  })

  return(
    <>
      {(() => { 
        switch (selectUser.status) {
          case 1:
            return (<span className="icon-win icon-win-text"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="text">{t('generic.status_level.' + selectUser.status)}</span></span>);
          case 2:
            return (<span className="icon-win icon-win-text silver"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="text">{t('generic.status_level.' + selectUser.status)}</span></span>);
          case 3:
            return (<span className="icon-win icon-win-text gold"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="text">{t('generic.status_level.' + selectUser.status)}</span></span>);
          case 4:
            return (<span className="icon-win icon-win-text platinum"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="text">{t('generic.status_level.' + selectUser.status)}</span></span>);
        }
      })()}

      <h3 className="pt-3 mb-2">{userGender} {currentUser.lastName}</h3>
    </>
  )
}
export default TropheeNameBlock


