import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import logoNissanNow from "../../assets/images/logo.png";

import {useTranslation} from 'react-i18next';
import moment from "moment";
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const UserClientInfo = (props) => {
    let params = useParams();
    const history = useHistory();
    const [isAnonyme, setIsAnonyme] = useState(!!params.id);
    const [vin, setVin] = useState(params.id ?? null);
    const [customer, setCustomer] = useState(JSON.parse(localStorage.getItem('customer')));
    const [genderActive, setGenderActive] = useState( '');
    const [checkBox, setCheckBox] = useState(false);
    const [firstName, setFirstName] = useState(customer ? customer.firstName : '');
    const [middleName, setMiddleName] = useState(customer ? customer.middleName : '');
    const [lastName, setLastName] = useState(customer ? customer.lastName : '');
    const [birthday, setBirthday] = useState((customer && customer.birthday) ? new Date(customer.birthday) : '');
    const [startDate, setStartDate] = useState(new Date());
    const [email, setEmail] = useState(localStorage.getItem('email')  ? localStorage.getItem('email')  : '');
    const [confirmationEmail, setconfirmationEmail] = useState(localStorage.getItem('confirmationEmail') ? localStorage.getItem('confirmationEmail') : '');
    const [validCheckBox, setvalidCheckBox] = useState(false);
    const [validFirstName, setvalidFirstName] = useState((firstName.trim().length) > 0 ? true : false);
    const [validLastName, setvalidLastName] = useState((lastName.trim().length) > 0 ? true : false);
    const [validMiddleName, setvalidMiddleName] = useState(false);
    const [car, setCar] = useState(JSON.parse(localStorage.getItem('car')));
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

    const formValidation = (genderActive, lastName, firstName, birthday) => {
        if(genderActive === '' || lastName === '' || firstName === ''|| birthday === '') {
            setNextButtonDisabled(true)
        } else {
            setNextButtonDisabled(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('email', email);
        localStorage.setItem('middleName', middleName);
        localStorage.setItem('lastName', lastName);
        localStorage.setItem('car', JSON.stringify(car));
        localStorage.setItem('confidentiality', checkBox);
        localStorage.setItem('gender', genderActive);
        localStorage.setItem('startDate', startDate);
        localStorage.setItem('birthday', moment(birthday).format('YYYY-MM-DD'));

        history.push({
            pathname: isAnonyme ? '/n/'+vin+'/7' : '/user-client-info-next',
            state: {
                email: email,
                confirmationEmail: confirmationEmail,
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                birthdate: moment(birthday).format('YYYY-MM-DD'),
                confidentiality: checkBox === 'on',
                gender: genderActive,
                startDate: startDate
            }
        })
    }

    const isGenderActive = (active) => {
        setGenderActive(active);
        formValidation(active, lastName, firstName, birthday)
    };

    const handleChangeFirstName = (event) => {
        setFirstName(event);

        if(event === ''){
            setvalidFirstName(false);
        } else{
            setvalidFirstName(true);
        }
        formValidation(genderActive, lastName, event, birthday)
    }

    const handleChangeLastName = (event) => {
        setLastName(event);
        if(event === ''){
            setvalidLastName(false);
        } else{
            setvalidLastName(true);
        }
        formValidation(genderActive, event, firstName, birthday)
    }

    const handleChangeBirthday = (event) => {
        setBirthday(event);

        formValidation(genderActive, lastName, firstName, event)
    }

    const handleChangeMiddleName = (event) => {
        setMiddleName(event);
        if(event === ''){
            setvalidMiddleName(false);
        } else{
            setvalidMiddleName(true);
        }

    }

    const handleChangeCheckbox = (event) => {
        setCheckBox(event);
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                    <h2 className="with-border">{t('Client Information')}</h2>
                                    <p>{t("Create your account to get access to the loyalty program.")}</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="required form-label">{t('Gender')}</label>
                                                {validCheckBox && <span className="icon-check icon"></span>}
                                                <div aria-label="title" role="group" className="btn-group">
                                                    <button name="0" type="button"
                                                            className={genderActive === 1 ? 'selected btn btn-primary' : 'btn btn-primary'}
                                                            onClick={() => isGenderActive(1)}>{t('Mr')}.
                                                    </button>
                                                    <button name="1" type="button"
                                                            className={genderActive === 0 ? 'selected btn btn-primary' : 'btn btn-primary'}
                                                            onClick={() => isGenderActive(0)}>{t('Ms')}.
                                                    </button>
                                                    <button name="2" type="button"
                                                            className={genderActive === 2 ? 'selected btn btn-primary' : 'btn btn-primary'}
                                                            onClick={() => isGenderActive(2)}>{t('Mx')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-4">
                                                <label className="required form-label">{t('FIRSTNAME')}</label>
                                                {validFirstName && <span className="icon-check icon"></span>}
                                                <input type="text" name="firstname" value={firstName}
                                                       onChange={event => handleChangeFirstName(event.target.value)} className="form-control"
                                                       required/>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label className="form-label">{t('MIDDLENAME')}</label>
                                                {validMiddleName && <span className="icon-check icon"></span>}
                                                <input type="text" name="middlename" value={middleName}
                                                       onChange={event => handleChangeMiddleName(event.target.value)}
                                                       className="form-control"/>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label className="required form-label">{t('LASTNAME')}</label>
                                                {validLastName && <span className="icon-check icon"></span>}
                                                <input type="text" name="lastname" value={lastName}
                                                       onChange={event => handleChangeLastName(event.target.value)} className="form-control"
                                                       required/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label className="required form-label">{t('BIRTHDATE')}</label>
                                                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" default-value={birthday}
                                                            dropdownMode="select"
                                                            selected={birthday}
                                                            onChange={event => handleChangeBirthday(event)}
                                                            // onChange={(date) => setBirthday(date)}
                                                required/>
                                            </div>
                                            <div className="form-group col-md-6 checkbox-custom">
                                                <input type="checkbox" id="optin" name="optin"
                                                       className="form-control"
                                                       onChange={event => handleChangeCheckbox(event.target.value)}/>
                                                <label htmlFor="optin" className="form-label multiline">{t('By creating my account I confirm to be an owner of a Nissan and have read the personal data and cookie management policy')}</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <a href="#" onClick={e => e.preventDefault()} data-info={t('In order to avoid duplicata of your Nissan Now account if you already have one or if someone try to create an account using your information.')} className="info-birthday text-decoration-underline">{t('Why do we ask for your birthdate ?')}</a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="required-field mb-4"><span>*</span>{t('mandatory fields')}</div>
                                            </div>
                                            <div className="col-md-6">
                                                <button type="submit"
                                                        className={  nextButtonDisabled ? "disabled button red mx-auto mr-md-0" : "button red mx-auto mr-md-0" }
                                                >{t('Next')}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserClientInfo
