import React from "react"
import PropTypes from "prop-types"
import logoNissanNow from "../../assets/images/logo.png";

const OnboardLayout = ({ children }) => {
  return (
    <>
      <div className="wrapper pt-0">

        <div className="container">
          <main>
            {children}
          </main>
        </div>
      </div>
    </>
  )

}

OnboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OnboardLayout
