import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import logoNissanNow from "../../assets/images/logo.png";
import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const UserCarFound = (props) => {
    let params = useParams();
    const isAnonyme = !!params.id;
    const history = useHistory();
    const [car, setCar] = useState(JSON.parse(localStorage.getItem('car')));
    const [mileage, setMileage] = useState(car.currentMileage ? car.currentMileage : 0);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
    const [model, setModel] = useState(localStorage.model);

    const formValidation = (event) => {
        if(event === '') {
           setNextButtonDisabled(true)
        } else {
            setNextButtonDisabled(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem('mileage', mileage);
        localStorage.setItem('car', JSON.stringify(car));
        history.push({
            pathname: isAnonyme ? '/n/'+params.id+'/4' : '/user-last-maintenance',
        })
    }

    const handleChangMileage = (event) => {
        setMileage(event);
        formValidation(event);
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="text-center board-info-title mb-4">
                            <h1 className="text-center mb-5">{t('CAR FOUND')} <span className="icon-check icon"></span>
                            </h1>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-lg-12 col-xl-10">
                            <div className="white-container">

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-4 text-center">
                                            <img src={localStorage.image} alt="Nissan Now" width="160"
                                                 className="mr-auto"/>
                                            <p>{model}</p>
                                            <p>{car.licencePlate}</p>
                                        </div>
                                        <div className="col-md-8">
                                            <h2 className="with-border-top with-border-bottom">{t('PLEASE UPDATE YOUR MILEAGE')}</h2>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-units" data-units="KM">
                                                        <input type="number" name="mileage" value={mileage}
                                                               onChange={event => handleChangMileage(event.target.value)}
                                                               className="form-control"
                                                               min="0" max="999999" maxLength="6" placeholder="12 222"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <button type="submit"
                                                            className={  nextButtonDisabled ? "disabled button red mx-auto mr-md-0" : "button red mx-auto mr-md-0" }
                                                    >{t('Next')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="red-text">{t('Please update your mileage')}</p>
                                                <p>{t('You can fill an approximate number for now and correct it later')}.</p>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                          </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserCarFound
