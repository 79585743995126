import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from 'react-i18next';
import UserService from "../../services/UserService";
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";

const UserInformation = (props) => {
    let params = useParams();
    const {t} = useTranslation();

    const { i18n } = useTranslation();
    const isAnonyme = !!params.id;
    const history = useHistory();
    const [vin, setVin] = useState('')
    const [customer, setCustomer] = useState(null);
    const [car, setCar] = useState(null);
    const [address, setAddress] = useState(null);
    const [initalized, setInitialized] = useState(false);

    async function VinToRedirect(vin) {
        if (!vin) {
            return false;
        }
        const response = await fetch(process.env.REACT_APP_API_URL + 'onboarding/check/' + vin);
        const data = await response.json();
        const isToRedirect = data['isVinToRedirect'];
        if(isToRedirect === true) {
            return window.location.replace('https://nissannow.nl/nl-NL/sessions/signup');
        }

        return true;
    }

    function vinIsValid(vin) {
        if(VinToRedirect(vin) === true) {
            throw new Error('VIN to redirect');
            return false;
        }
        return true;
    }

    useEffect(() => {
        if(vinIsValid(vin)) {
            if (isAnonyme && !initalized) {
                localStorage.setItem('car_id', params.id);
                UserService.getCarByToken({
                    "id": params.id,
                    "token": localStorage.getItem('token')
                })
                    .then((res) => {
                        setCustomer(res.data.customer);
                        setAddress(res.data.address);
                        setCar(res.data.car);
                        setVin(res.data.car.vin);

                        localStorage.setItem('vin', res.data.car.vin);
                        localStorage.setItem('image', res.data.car.modelImage);
                        localStorage.setItem('model', res.data.car.modelName);
                        localStorage.setItem('car', JSON.stringify(res.data.car));
                        localStorage.setItem('address', JSON.stringify(res.data.address));

                        // customer-related
                        if(res.data.customer != null) {
                            localStorage.setItem('language', res.data.customer.language);
                            i18n.changeLanguage(res.data.customer.language);
                            localStorage.setItem('customer', JSON.stringify(res.data.customer ?? null));
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error.toString().includes("404")) {
                            history.push({
                                pathname: isAnonyme ? '/n/' + params.id + '/vin-not-found' : '/user-car-associated',
                                state: {
                                    language: localStorage.getItem('language') ?? 'fr-BE',
                                    errorMessage: t('We have not found a vehicle associated with this VIN.')
                                }
                            })
                        }
                        else if (error.toString().includes("400")) {
                            history.push({
                                pathname: isAnonyme ? '/n/' + params.id + '/already-associated' : '/user-car-associated',
                                state: {
                                    errorMessage: t('The car you wish to add is already associated with another user')
                                }
                            })
                        } else {
                            history.push({
                                pathname: isAnonyme ? '/n/' + params.id + '/already-associated' : '/user-car-associated',
                                state: {
                                    errorMessage: t('This VIN is not recognized')
                                }
                            })
                        }
                    });
                setInitialized(true);
            }
        }
        else {
            setCustomer(null);
            setAddress(null);
        }
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        UserService.getCarByToken({
            "id": params.id,
            "token": localStorage.getItem('token')
        })
            .then((res) => {
                localStorage.setItem('vin', res.data.car.vin);
                localStorage.setItem('car', JSON.stringify(res.data.car));
                localStorage.setItem('customer', JSON.stringify(customer));
                localStorage.setItem('address', JSON.stringify(res.data.address));
                localStorage.setItem('image', res.data.car.modelImage);
                localStorage.setItem('model', res.data.car.modelName);

                history.push({
                        pathname: isAnonyme ? '/n/' + params.id + '/2' : '/user-car-information',
                    }
                )
            })
            .catch((error) => {
                if (error.toString().includes("400")) {
                    history.push({
                        pathname: isAnonyme ? '/n/' + params.id + '/already-associated' : '/user-car-associated',
                        state: {
                            errorMessage: t('This VIN is not recognized')
                        }
                    })
                } else {
                    history.push({
                        pathname: isAnonyme ? '/n/' + params.id + '/already-associated' : '/user-car-associated',
                        state: {
                            errorMessage: t('The car you wish to add is already associated with another user')
                        }
                    })
                }
            })
    }

    const customerNewInfos = (event) => {

        UserService.getCarByToken({
            "id": params.id,
            "token": localStorage.getItem('token')
        })
            .then((res) => {
                localStorage.setItem('vin', res.data.car.vin);
                localStorage.setItem('car', JSON.stringify(res.data.car));
                localStorage.setItem('customer', null);
                localStorage.setItem('address', JSON.stringify(res.data.address));
                localStorage.setItem('image', res.data.car.modelImage);
                localStorage.setItem('model', res.data.car.modelName);
                history.push({
                    pathname: '/user-car-information',
                })
            })
            .catch((error) => {
                console.log(error)
                history.push({
                    pathname: '/user-car-associated'
                })
            })
    }

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons/>
            <div className="container">

                <main>
                    <OnboardLayout>
                        {isAnonyme &&
                            <div className="text-center board-info-title mb-4">
                                <h2>{t('WE HAVE FOUND AN ID ASSOCIATED')} <br/> {t('WITH THIS VIN')}</h2>
                                <span className="grey-title">{t('Please check the infos before proceeding')}</span>
                            </div>
                        }
                        {!isAnonyme &&
                            <div className="text-center board-info-title mb-4">
                                <h2>{t('WE HAVE FOUND A CLIENT ASSOCIATED')} <br/> {t('WITH THIS VIN')}</h2>
                                <span
                                    className="grey-title">{t('Please check the client ID before proceeding')}</span>
                            </div>
                        }
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                    <h2 className="with-border"> {isAnonyme ? t('WE FOUND AN ID') : t('We have found a client associated with this vin')} </h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row mt-4">
                                            <div className="col-md-6">
                                                {customer &&
                                                    <div className="input-units">
                                                        <h2>{customer.email}</h2>
                                                        <h2>{customer.firstName} {customer.lastName}</h2>
                                                        {address &&
                                                            <p>{address.streetNumber} {address.streetName}</p>}
                                                        {address && <p>{address.postalCode} {address.city}</p>}
                                                        {vin && <p>{vin}</p>}
                                                    </div>
                                                }
                                                <div className="input-units">
                                                    <p>{t('Please check the infos before proceeding')}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <button type="submit"
                                                        className="button red mx-auto mr-md-0">{!isAnonyme ? t('Proceed with this user') : t('proceed with this infos')}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                    {!isAnonyme &&
                        <div className="text-center">
                            <a className="board-info-footer"
                               onClick={() => customerNewInfos()}>{t('It\'s not your client ?')}</a>
                        </div>
                    }
                </main>

            </div>

        </div>
    );
}

export default UserInformation
