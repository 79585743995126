import React from 'react';
import BasicPage from "../components/BasicPage";

import { useTranslation } from 'react-i18next';

const PasswordChoiceConfirmation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <BasicPage icon="icon-check" title={t('Password set')} content={t('Your password has been successfully set and linked to your account.')} buttonText={t('Go to homepage')} buttonLink="/" />
    </>
  );
}

export default PasswordChoiceConfirmation