import React, { useState, useEffect } from 'react';

import UserManage from "../components/Modale/UserManage";
import DealerService from "../services/DealerService";
import Layout from "../components/Layout";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import UserService from "../services/UserService";
import {ExportToExcel} from '../components/ExportToExcel/ExportToExcel'
import Loader from "react-loader-spinner";
import _ from "lodash";

const DirectorBoard = (props) => {
  const [showModal, setShowModal] = useState(false);

  // Three modal types = "new" or "resend" or "revoke"
  const [modalType, setModalType] = useState('');

  /* Datas from API CAll */
  const [datas, setDatas] = useState([]);
  const [dealerList, setDealerList] = useState([]);

  const [onboarders, setOnboarders] = useState([]);
  const [dealerships, setDealerships] = useState([]);
  const [exportArray, setExportArray] = useState([]);

  /* Local data used for display */
  const [tableDatas, setTableDatas] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  /* Filters */
  const [searchFilter, setSearchFilter] = useState('');
  const [searchPlateFilter, setSearchPlateFilter] = useState("");
  const [onboardedFilter, setOnboardedFilter] = useState('');
  const [dealershipFilter, setDealershipFilter] = useState('');
  const [activeFilter, setActiveFilter] = useState('');
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  

  useEffect(() => {
    /* Verify roles */
    let role = localStorage.getItem('role')
    if(role === null)  {
      localStorage.clear();
      history.push('/login');
    } else if (role && !role.includes('ROLE_DIRECTOR') && !role.includes('ROLE_ADMIN')) {
      history.push('/login');
    }
    
    /* Initial API Calls */
    UserService.getDealerships({ token: localStorage.getItem('token') })
    .then((response) => {
      setDealerships(response.data.dealerships)
    })
    .catch((error) => {
      console.log(error);
      if (error.toString().includes("401")) {
        localStorage.clear();
        history.push('/login');
      }
    });

    DealerService.getDirectoDealers({ token: localStorage.getItem('token') })
    .then((response) => {
        setDatas(response.data.users);
        setTableDatas(response.data.users);
        setLoader(false);
      })
    .catch((error) => {
        console.log(error);
      if (error.toString().includes("401")) {
        localStorage.clear();
        history.push('/login');
      }
    });

    UserService.getOnboarders({ token: localStorage.getItem('token') })
    .then((response) => {
      setOnboarders(response.data.onboarders)
    })
    .catch((error) => {
      console.log(error);
      if (error.toString().includes("401")) {
        localStorage.clear();
        history.push('/login');
      }
    });

    UserService.getDirectorTimelines({
        "token": localStorage.getItem('token'),
      }).then((res) => {
        setExportArray(res.data.user);
      }).catch((error) => {
        console.log(error);
    });

  },[])

  /* Filter methods  */
  const searchFilterChange = (e) => {
    setSearchFilter(e.target.value);
    globalFilter(e.target.value, searchPlateFilter, dealershipFilter, onboardedFilter, activeFilter);
  }

  const searchPlateFilterChange = (e) => {
    setSearchPlateFilter(e.target.value);
    globalFilter(searchFilter, e.target.value, dealershipFilter, onboardedFilter, activeFilter);
  }

  const onboardedFilterChange = (e) => {
    if(e.target.value==="all"){
      setOnboardedFilter(null);
      globalFilter(searchFilter, searchPlateFilter, dealershipFilter, null, activeFilter);
    }else{
      setOnboardedFilter(e.target.value);
      globalFilter(searchFilter, searchPlateFilter, dealershipFilter, e.target.value, activeFilter);
    }
  }

  const dealerShipFilterChange = (e) => {
    if(e.target.value==="all"){
      setDealershipFilter(null);
      globalFilter(searchFilter, searchPlateFilter, null, onboardedFilter, activeFilter);
    }else{
      setDealershipFilter(e.target.value);
      globalFilter(searchFilter, searchPlateFilter, e.target.value, onboardedFilter, activeFilter);
    }
  }

  const activeFilterChange = (e) => {
    if(e.target.value==="all"){
      setOnboardedFilter(null);
      globalFilter(searchFilter, searchPlateFilter, dealershipFilter, onboardedFilter, null);
    }else {
      setActiveFilter(e.target.value);
      globalFilter(searchFilter, searchPlateFilter, dealershipFilter, onboardedFilter, e.target.value);
    }
  }

  const translateStatus = (status) => {
    if(status === 1){
        status = 'Bronze'
    } else if (status === 2){
        status = 'Silver'
    } else if (status === 3){
        status = 'Gold'
    } else if (status === 4){
        status = 'Premium'
    }
    return status;
  }

  /*
   * Filtering with all the filters
   */
  const globalFilter = (searchFilterParams="", searchPlateFilterParams="", dealershipFilterParams="", onboardedFilterParams="", activeFilterParams="") => {
    let generalFilter = data => {
      //When nothing to filter, display all
      if(searchPlateFilterParams == '' && searchFilterParams == '' && dealershipFilterParams=='' && onboardedFilterParams == '' && activeFilterParams ==''){
        return true;
      }

      //When licence plate is null, don't display on search
      if(data.licence_plate == null && searchFilterParams == '' && dealershipFilterParams=='' && onboardedFilterParams == '' && activeFilterParams ==''){
        return false;
      }

      if(searchFilterParams && (!(data.first_name+ ' '+data.last_name).toLowerCase().includes(searchFilterParams.toLowerCase()))) {
        return false;
      }

      if(searchPlateFilterParams && (data.licence_plate != null && !(data.licence_plate).toUpperCase().includes(searchPlateFilterParams.toUpperCase()))) {
        return false;
      }

      if(dealershipFilterParams && data.dealer !== dealershipFilterParams) {
        return false;
      }
      
      if(onboardedFilterParams && data.onboarded_by !== onboardedFilterParams) {
        return false;
      }

      let nicename = data.validation_date !== null ? "Active" : "Inactive";

      if(activeFilterParams && nicename !== activeFilterParams) {
        return false;
      }

      return true;
    }

    setTableDatas(datas.filter(generalFilter));
  }

  const sendConfirmCustomerMAil = (user) => {
    UserService.sendConfirmCustomerEmail({
      "email": user.email,
      "token": localStorage.getItem('token'),
      "lang": user.language
    }).then((res) => {
      history.push({
        pathname: '/reset-link-sent-customer',
        state: { email: user.email }
      })
    }).catch((error) => {
      console.log(error);
  });
  }
  /* /Filter methods */

  /* Display methods
   * status takes old_dealer value from api
   * selectedUser contains all the datas of the current user. It's given to the openModal method when needed
   */

  const actions = (status,selectedUser) => {
    if (status !== null) {
      return (<span className="dark-red text-decoration-none" onClick={event => redirectToTimeline(selectedUser)}><span className="icon-magazine fs-20"></span></span>);
    } else {
      return (<span onClick={event => sendConfirmCustomerMAil(selectedUser)} className="dark-red text-decoration-none dark-grey cursor-pointer"><span className="icon-inbox align-middle"></span> {t('Re-send activation link')}</span>);
    }
  }

  const redirectToTimeline = (selectedUser) => {
    localStorage.setItem('selectedUser', JSON.stringify(selectedUser));
    localStorage.setItem('previousPage', 'directorboard');
    history.push({
      pathname: "/user-timeline",
      state: {
        selectedUser: localStorage.setItem('selectedUser', JSON.stringify(selectedUser))
    }
    })
  }

  const onboardedFilterOptions = () => {
    return (
      onboarders.length > 0 &&
      onboarders
      .sort((a, b) =>{
        if(a.onboarded_by < b.onboarded_by) { return -1; }
        if(a.onboarded_by > b.onboarded_by) { return 1; }
        return 0;
      })
      .map((data) => {
        if(data.onboarded_by != null)
        {
          return(
              <option value={data.id} key={data.id}>{data.onboarded_by}</option>
          )}
        }
      )
    )
  }

  const dealershipFilterOptions = () => {
    return (
      dealerships.map((data) => {
        return(
              <option value={data.id} key={data.id}>{data.dealer}</option>
            )
      })
    )
  }
  /* Display methods */

  /* Modal methods */

    /*
     * modalType = "new", 'resend' or "revoke"
     */
    const openModal = (modalType, user={}) => {
      setShowModal(true);
      setModalType(modalType);
      setSelectedUser(user);
    }

    const handleClose = () => {
      setShowModal(false);
      setSelectedUser({});
    }

    const redirectTo = () => {
      history.push('/user-add-car')
    }

    const getExportToExcel = () => {
      const mergedMap = {}
      // Group list elements by last_name
      for (const el of exportArray) {
        if (el.last_name in mergedMap) {
          mergedMap[el.last_name].push(el)
        } else {
          mergedMap[el.last_name] = [el]
        }
      }
      
      // Iterate over "user" groups, modifying field names.
      const mergedList = []
      for (const last_name in mergedMap) {
        const elCount = mergedMap[last_name].length
        // If there's only one entry for this "last_name", keep it as is, 
        // then continue to next user.
        if (elCount === 1){
          mergedList.push(mergedMap[last_name][0])
          continue
        }
        const mergedUser = mergedMap[last_name].reduce((merged, el, index) => ({
          // Keep whatever keys are already here
          ...merged,
          // last_name and status are assumed to always be the same
          // for a given user, so they're safe to overwrite each time
          last_name: el.last_name,
          status: el.status,
          name: el.name,
          // type and due_date might be unique for each entry, so
          // we add an index to the field name and copy the new value in
          [`type${index + 1}`]: el.type,
          [`due_date${index + 1}`]: el.due_date,
        }), {})
        mergedList.push(mergedUser)
      }
      const dateDay = new Date();
      const fileName = 'UsersTimelines_'+moment(dateDay).format('YYYY/MM/DD');
      //  console.log(JSON.stringify(mergedList, null, 2))
      // console.log(JSON.stringify(mergedList));

      return (
        <div className="App">
          <ExportToExcel apiData={mergedList} fileName={fileName} />
        </div>
      );
      
     }

     const showContent = (loader) => {
      if(loader == true)
      {
        return (
          <div style={{ textAlign: "center" }}>
          <Loader
            type="TailSpin"
            color="#C0092D"
            height={100}
            width={100}
          /> 
        </div>
        )
      } else {
        return (
          <table className="table table-striped table-align-middle fs-13">
            <thead>
            <tr className="font-weight-bold">
              <th scope="col">{t('Name')}</th>
              <th scope="col">{t('Dealer')}</th>
              <th scope="col">{t('Member Since')}</th>
              <th scope="col">{t('Current Level')}</th>
              <th scope="col">{t('Onboarded by')}</th>
              <th scope="col">{t('Inactive')}</th>
              <th scope="col">{t('Licence Plate')}</th>
              <th scope="col">{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {
              tableDatas.map((data, index) => {
              return(
                <tr key={index}>
                  <td>{data.firstName} {data.lastName}</td>
                  <td>{data.dealer}</td>
                  <td>{moment(data.creationDate).format('YYYY/MM/DD')}</td>
                  <td>{translateStatus(data.status)}</td>
                  <td>{data.onboarded_by}</td>
                  <td>{data.creationDate !== null ? '' : t('X')}</td>
                  <td>{data.licence_plate}</td>
                  <td>{actions(data.creationDate, data)}</td>
                </tr>
              )})
            }
          </tbody>
        </table>
        )
      }
    }

  /* /Modal methods */

  const { t } = useTranslation();

  return (
    <Layout>
      <div className="white-container">
        <div className="row mb-3">
          <div className="col-md-8">
            <h2 className="with-border text-uppercase">{t('Client - DealerShip')}</h2>
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <button className="button red with-icon fs-13" onClick={() => redirectTo()}><span className="icon-profile icon-profile-new icon"></span><span className="pl-2">{t('Add a new client')}</span></button>
          </div>
          <div className="col-md-1 fs-30">
            {getExportToExcel()}
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-6 col-lg-2">
            <div className="input-with-icon">
              <input type="text" value={searchFilter} onChange={searchFilterChange} className="form-control fs-13" placeholder={t('Type a name')} />
              <span className="icon-search icon"></span>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="input-with-icon">
              <input type="text" value={searchPlateFilter} onChange={searchPlateFilterChange} className="form-control fs-13" placeholder={t('Type a plate number')} />
              <span className="icon-search icon"></span>
            </div>
          </div>
          <div className="col-md-6 col-lg-2">
            <div className="select">
              <select defaultValue="" onChange={(event) => dealerShipFilterChange(event)}  className="form-control fs-13">
                <option disabled value="" >{t('Dealership')}</option>
                <option value="">{t('All')}</option>
                {dealershipFilterOptions()}
              </select>
            </div>
          </div>
          <div className="col-md-6 col-lg-2">
            <div className="select">
              <select defaultValue="" onChange={(event) => onboardedFilterChange(event)}  className="form-control fs-13">
                <option disabled value="" >{t('Onboarded by')}</option>
                <option value="">{t('All')}</option>
                {onboardedFilterOptions()}
              </select>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
          <div className="select">
              <select defaultValue="" onChange={(event) => activeFilterChange(event)}  className="form-control fs-13">
                <option disabled value="" >{t('Activation status')}</option>
                <option value="all">{t('All')}</option>
                <option value="Active">{t('Active')}</option>
                <option value="Inactive">{t('Inactive')}</option>
              </select>
            </div>
          </div>
        </div>
        {showContent(loader)}
      </div>

      { showModal &&
      <UserManage dealerList={dealerList} showModal={showModal} handleClose={() => handleClose()} modalType={modalType} selectedUser={selectedUser} />
      }

    </Layout>
  );
}


export default DirectorBoard 
