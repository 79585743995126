import React from "react"
import moment from "moment";
import {useTranslation} from 'react-i18next';

const RewardBlock = ({currentUser}) => {
  const {t} = useTranslation();
  return(
    <div className="">
      <a to="/rewards/reward-history">
          <p className="mb-0 picto-link">
                <span className="d-inline-block grey-border w-50">
                    {t('my_profil.current_level')}
                </span>
                <span className="d-inline-block w-50 pl-2">
                    <strong className="dark-red">{t('generic.status_level.' + currentUser.status)}</strong>
                </span><br />
                <span className="d-inline-block grey-border w-50">
                     {t('my_profil.points')}
                </span>
                <span className="d-inline-block w-50 pl-2">
                    <strong className="dark-red">
                      {currentUser.rewardPoints} PTS
                    </strong>
                </span><br />
                <span className="d-inline-block grey-border w-50">
                    {t('my_profil.member_since')}
                </span>
                <span className="d-inline-block w-50 pl-2">
                    <strong className="dark-red">{moment(currentUser.validationDate, moment.ISO_8601).format('DD/MM/YYYY')}</strong>
                </span><br />
                <span className="d-inline-block grey-border w-50">
                    {t('my_profil.rewards_used')}
                </span><span className="d-inline-block w-50 pl-2">
                    <strong className="dark-red">{currentUser.countOfUserReward}</strong>
                </span>
          </p>
      </a>
    </div>
  )
}

export default RewardBlock


