import React from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";

const UserCarNotFound = (props) => {
    const history = useHistory();
    let params = useParams();
    const isAnonyme = !!params.id;
    const errorMessage = history.location.state.errorMessage

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push({
            pathname: isAnonyme ? '/n/'+params.id : '/user-add-car',
        })
    }

    if(localStorage.getItem('token') === null && !isAnonyme){
        history.push('/login');
    }

    const {t} = useTranslation();
    let redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_REGISTER_NL_NL;
    switch (localStorage.getItem('language')) {
        case 'nl-BE':
            redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_REGISTER_NL_BE;
            break;
        default:
        case 'fr-BE':
            redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_REGISTER_FR_BE;
            break;
        case 'fr-LU':
            redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL_REGISTER_FR_LU;
            break;
    }

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                    <h2 className="text-center mb-5"><span className="icon-close icon"></span></h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <p className="mb-5">{errorMessage}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <a href={redirect} className="button back red mx-auto">{t('GO BACK')}</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserCarNotFound
