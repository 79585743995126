import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import logoNissanNow from "../assets/images/logo.png";
import { useTranslation } from 'react-i18next';


const Layout = ({ children }) => {

  const history = useHistory();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.clear();
    history.push('/');
  };

  const { t } = useTranslation();

  return (
    <>
    <div className="wrapper">
      <header className="header py-2">
        <div className="container d-flex align-items-center">
          <img src={logoNissanNow} alt="Nissan Now" width="160" className="mr-auto logo-width"/>
          <span className="dark-red cursor-pointer" onClick={()=>logout()}><span className="icon-logout-2 icon align-middle"></span>{t('Logout')}</span>
        </div>
      </header>
      <div className="container">
        <main>
          {children}
        </main>
      </div>
    </div>
    </>
  )

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
