import React, {useEffect, useState} from "react"
import moment from "moment";
import {useHistory} from "react-router-dom";
import UserService from "../../services/UserService";
import {useTranslation} from 'react-i18next';
import ReactImageFallback from "react-image-fallback";
import GenericVehicle from "../../assets/images/vehicle/generic.png";
import "../../assets/sass/maintenance.sass";
import logoNissanNow from "../../assets/images/logo.png";
import TropheeNameBlock from "../media-components/trophee-name-block";
import RewardBlock from "../media-components/reward-block";
import axios from 'axios';
import {ExportToExcel} from '../ExportToExcel/ExportToExcel'
import Loader from "react-loader-spinner";

class BodyClass extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const Timeline = () => {

  const [car, setCar] = useState('');
  const [model, setModel] = useState('');
  const [reminders, setReminders] = useState([]);
  const [lastUpdateDate, setLastUpdateDate] = useState('2020-02-20T08:56:20+00:00');
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentCars, setCurrentCars] = useState([]);
  const [currentDealer, setCurrentDealer] = useState([]);
  const [selectedCar, setSelectedCar] = useState([]);
  const [currentUser, setCurrentUser] = useState('');
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [cars, setCars] =  useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('selectedUser')));
  const [exportData, setExportData] = useState([]);
  const [loader, setLoader] = useState(true);

  const {t} = useTranslation();
  const history = useHistory();
  const previousPage = localStorage.getItem('previousPage')

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.clear();
    history.push('/');
  };

  const [selectedWhen, setSelectedWhen] = useState(
    {
    value: 'next',
    name: 'Next Events'
  });

  const [times, setTimes] = useState([
    {
      value: 'next',
      name: t('timeline.next_events')
    },
    {
      value: 'past',
      name: t('timeline.past_events')
    }
  ]);
  
  useEffect(() => {
    /* Initial API Calls */
    //Donnée utilisateurs liées aux events
    UserService.getMeUserId({
      "id": user.id,
      "token": localStorage.getItem('token')
  }).then( (resUser) => {
    localStorage.setItem('currentUser', JSON.stringify(resUser.data.user));
    setCurrentUser(JSON.parse(localStorage.getItem('currentUser')));
    setTooltipOpened(!resUser.data.user.welcomeTimeline);

    const nbEvents = resUser.data.user.events.length;
    var events = Array();
    var eventReminders = Array();
    localStorage.setItem('currentEvents', JSON.stringify(events));
    for (let i = 0; i < nbEvents; i++) {
      UserService.userEvents({
        "url": resUser.data.user.events[i],
        "token": localStorage.getItem('token')
      }).then((resEvents) => {
        if (resEvents.status === 200) {
          if (typeof window !== 'undefined') {
            events.push(resEvents.data);
            if (!resEvents.data.done && !resEvents.data.reminderSeen && moment(new Date()).diff(resEvents.data.reminderDate, 'days') >= 0) {
              eventReminders.push(resEvents.data);
            }
            setReminders(eventReminders);
            localStorage.setItem('currentEvents', JSON.stringify(events));
          }
        }
      }).catch( (error) => {
        
      });
    }

    //Dealer
    UserService.userDealer({
      "url": resUser.data.user.dealer,
      "token": localStorage.getItem('token')
    }).then( (r) => {
        if (r.status === 200) {
            localStorage.setItem('currentDealer', JSON.stringify({
              id: r.data.id,
              name: r.data.name,
              address: r.data.address,
              postcode: r.data.postcode,
              city: r.data.city,
              phone: r.data.phone,
              bookingUrl: r.data.bookingUrl
            }));

            setCurrentDealer(JSON.parse(localStorage.getItem('currentDealer')));
            

            const nbCars = resUser.data.user.cars.length;
            var cars = Array();

            localStorage.setItem('currentCars', JSON.stringify(cars));

            // Cars
            for (var i = 0; i < nbCars; i++) {
              UserService.userCarTimeline({
                "url": resUser.data.user.cars[i],
                "token": localStorage.getItem('token')
              }).then( (c) => {
                  if (c.status === 200) {
                    if (typeof window !== 'undefined') {
                      var couple = [];
                      UserService.userCarTimeline({
                        "url": c.data.carModel,
                        "token": localStorage.getItem('token')
                      }).then( (m) => {
                          if (m.status === 200) {
                            if (typeof window !== 'undefined') {
                              couple.push(c.data);
                              couple.push(m.data);

                              cars.push(couple);

                              localStorage.setItem('currentCars', JSON.stringify(cars));

                              if (cars.length > 0) {
                                setCurrentCars(cars.map((car, index) => {
                                  setSelectedCar({
                                    id: car[0].id,
                                    name: car[1].name,
                                    image: car[1].image,
                                    isLeasePlan: car[0].isLeasePlan,
                                    licencePlate: car[0].licencePlate,
                                    firstRegistrationDate: car[0].firstRegistrationDate,
                                    lastMaintenanceDate: car[0].lastMaintenanceDate,
                                    updatedDate: car[0].updatedDate
                                  });

                                  if (cars.length - 1 === index) {
                                    getMyTimeline(car[0].id, selectedWhen.value);
                                  }

                                  return {
                                    id: car[0].id,
                                    name: car[1].name,
                                    image: car[1].image,
                                    isLeasePlan: car[0].isLeasePlan,
                                    licencePlate: car[0].licencePlate,
                                    firstRegistrationDate: car[0].firstRegistrationDate,
                                    lastMaintenanceDate: car[0].lastMaintenanceDate,
                                    updatedDate: car[0].updatedDate
                                  };

                                }));
                              }
                            }
                          }
                        }).catch( (error) => {
                        // console.log('error', error)
                      });
                    }
                  }
                }).catch( (error) => {
                // console.log('error', error)
              });
            }
          
        }
      });
  });


    
  },[])

  const getMyTimeline = (carId, when) => {
    if (carId !== undefined && selectedWhen.value === when) {
      UserService.getTimeline({
        "carId": carId,
        "token": localStorage.getItem('token')
      })
      .then((response) => {
        console.log(response);
        setAllEvents(response.data.events);
          setEvents(response.data.events[when]);
          setLoader(false);
        if (response.data.events['past'].length === 0) {
            setLastUpdateDate(selectedCar.firstRegistrationDate);
        } else {
            setLastUpdateDate(selectedCar.lastMaintenanceDate)
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
    } else {
      setEvents(allEvents[when]);
    }
  };

  const getIconCss = (eventType) => {

    switch(eventType) {
      case 1:
        return 'icon-calendar-maintenance';
      case 2:
        return 'icon-controle-technique';
      case 3:
        return 'icon-tire';
      case 4:
        return 'icon-tire';
      case 5:
        return 'icon-extension-garanti';
      case 6:
        return 'icon-contracts';
      case 7:
        return 'icon-assistance';
      case 8:
        return 'icon-card';
      case 9:
        return '';
    }
  }

  const getFooter = () => {
    if (true !== selectedCar.isLeasePlan) {
      return (
        <footer>
          <div className="d-flex links-contact-go">
            {
              localStorage.getItem('currentDealer').bookingUrl &&
              (
                <a className='dark-red' href={localStorage.getItem('currentDealer').bookingUrl} target="_blank"><span className="icon icon-calendar"></span>{t('timeline.buttons.button_book_dealer')} <span></span></a>
              )
            }
            {(!currentDealer.bookingUrl && currentDealer.address) &&
            (
              <a className='dark-red' href={encodeURI("https://www.google.com/maps/search/"+localStorage.getItem('currentDealer').name+' '+localStorage.getItem('currentDealer').address+' '+localStorage.getItem('currentDealer').postcode+' '+localStorage.getItem('currentDealer').city)} target="_blank"><span className="icon icon-pin-marker"></span>{t('timeline.buttons.button_visit_dealer')} <span></span></a>
            )
            }
          </div>
          <div className="d-flex links-contact-call">
            {localStorage.getItem('currentDealer').phone &&
            (
              <a className='dark-red' href={encodeURI("tel:"+localStorage.getItem('currentDealer').phone)} target="_blank"><span className="icon icon-phone"></span> <span>{t('timeline.buttons.button_call_dealer')}</span></a>
            )
            }
          </div>
        </footer>
      )
    }
  }

  const getEvents = (loader) => {
    if(loader == false){
      if (events && events.length > 0) {
        return (
          <div className="timeline-container">
        {events.map(eve => (
          <div className="event" key={eve.id}>
            <div className="event-icon">
              <span className={getIconCss(eve.type)}></span>
              {eve.done &&
              (
                <span className="icon-check alert-icon"></span>
              )
              }
              {!eve.done && (moment(new Date()).diff(eve.dueDate, 'days') >= 0) &&
              (
                <span className="icon-time alert-icon"></span>
              )
              }
            </div>
            <div className="container-event">
              <header>
                <h3 className="mb-1">{t('timeline.event.'+eve.type+'.title')}</h3>
                <h4 className="mb-0">
                    {
                    // moment(eve.validationDate).format('DD/MM/YYYY') ? moment(eve.validationDate).format('DD/MM/YYYY') : eve.dueDate
                    moment(eve.dueDate).format('DD/MM/YYYY')
                    }
                  </h4>
                {!eve.done && (moment(new Date()).diff(eve.dueDate, 'months') >= 1 && moment(new Date()).diff(eve.dueDate, 'months') < 2) &&
                (
                  <div className="alert-message"><span className="icon">!</span> 1 {t('timeline.mentions.month_late')}</div>
                )
                }
                {!eve.done && (moment(new Date()).diff(eve.dueDate, 'months') >= 2 && moment(new Date()).diff(eve.dueDate, 'months') <= 3) &&
                (
                  <div className="alert-message"><span className="icon">!</span> 2 {t('timeline.mentions.months_late')}</div>
                )
                }
              </header>
              <div className="text">
                {eve.rewardPoints &&
                    <div>
                      {t('timeline.event.reward_points_event', {'points': eve.rewardPoints})}<br/>
                    </div>
                }
                {t('timeline.event.'+eve.type+'.description')}
              </div>
              {/* {getFooter()} */}

            </div>
          </div>
        ))}
          </div>
        );
      }
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <br></br>
          <br></br>
          <Loader
            type="TailSpin"
            color="#C0092D"
            height={100}
            width={100}
            timeout={5000}
          /> 
          <br></br>
          <br></br>
        </div>
      )
    }
  };

  const getCars = () => {
    if (currentCars.length > 0 && selectedCar !== undefined) {
      return (
        <div className="dropdown">
          <a className="dropdown-toggle text darker-grey" href="#" role="button" id="dropdownMenuLink"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <ReactImageFallback
            src={selectedCar.image}
            fallbackImage={GenericVehicle}
            alt={selectedCar.name}
            className="img-fluid align-middle"
            width="50" />
            <span className="d-none d-md-inline ml-3"><strong>{selectedCar.name}</strong> {selectedCar.licencePlate}</span>
          </a>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {currentCars.map(car => (
              <a className="dropdown-item mb-2 mb-lg-1" href="" key={car.id} onClick={e => selectCar(car, e)}><strong>{car.name}</strong> {car.licencePlate}</a>
            ))}
          </div>
        </div>
      );
    }
  };

  const getTimes = () => {
    return (
      <div className="dropdown">
        <a className="dropdown-toggle text darker-grey" href="" role="button" key="" id="dropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <strong>{selectedWhen.name}</strong>
        </a>

        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink2">
          {times.map(time => (
            <a className="dropdown-item mb-2 mb-lg-1" href="" key={time.value} onClick={e => selectWhen(time, e)}>{time.name}</a>
            ))}
        </div>
      </div>
    );
  };

  const getReminder = () => {
    if (reminders.length > 0) {
      return (
        <div>
          {reminders.map(reminder => (

            <div className="tooltip-custom-event dark-red-border mb-4 mt-3 mx-3 mx-sm-0 mt-lg-0" key={reminder.id}>

              <a href="#" className="close" onClick={(e) => closeReminder(e, reminder.id)}><span>+</span></a>

              <h3 className="white"><span className="icon-questions align-middle fs-20 mr-2"></span> {t('timeline.reminder.title')} :</h3>

              <p className="white mb-0">
                {t('timeline.reminder."+reminder.type+".message')}&nbsp;
                {moment(reminder.dueDate).format('dd/mm/yyyy')}
              </p>
            </div>
            ))}
        </div>
      )
    }
  };

  const selectCar = (car, e) => {
    setSelectedCar(car);
    e.preventDefault();

    getMyTimeline(car.id, selectedWhen.value);
    content();
  };

  const selectWhen = (when, e) => {
    setSelectedWhen(when);

    e.preventDefault();

    getMyTimeline(selectedCar.id, when.value);
    content();
  };
  

  const closeTooltip = () => {
    UserService.welcomeTimeline({
      "type": "timeline",
      "token": localStorage.getItem('token'),
    }).then((response) => {
      if (typeof window !== 'undefined' ) {
        localStorage.setItem('currentUser', JSON.stringify(response.data.user));
      }
    })
    setTooltipOpened(false);
  };

  const closeReminder = (e, eventId) => {
    if (eventId !== undefined) {
      UserService.reminderSeen({
        "eventId": eventId,
        "token": localStorage.getItem('token'),
      }).then((response) => {
        // console.log(response);
      }).catch((error) => {
        console.log('error', error);
      })
    }
    e.preventDefault();
    e.target.parentNode.parentNode.remove();
  }

  const needToUpdateCar = () => {
    let now = moment(new Date());
    let lastUpdatedDate = moment(selectedCar.updatedDate);

    if (now.diff(lastUpdatedDate, 'months') > 6) {
      return (
      <div className="message-important mb-4">
        <p>{t('timeline.notice_box.1.description')}</p>
        <div className="text-center">
          <a to="/mycars/my-cars/" state={selectedCar.id} className="button mx-auto with-icon"><span className="icon-pencil icon"></span>{t('timeline.notice_box.1.button')}</a>
        </div>
      </div>
      )
    } else {
      return '';
    }
  };

  const getCarsMedia = () =>  {
    let table = []

    if (typeof window !== 'undefined') {
      if (JSON.parse(localStorage.getItem('currentCars'))) {
        for (let i = 0; i < JSON.parse(localStorage.getItem('currentCars')).length; i++) {
          table.push(<li key={i} className="mb-0 py-3 w-100"><a to="/mycars/my-cars" state={{id: JSON.parse(localStorage.getItem('currentCars'))[i][0].id}}>
            <span className="icon">
              <ReactImageFallback
                src={JSON.parse(localStorage.getItem('currentCars'))[i][1].image}
                fallbackImage={GenericVehicle}
                alt={JSON.parse(localStorage.getItem('currentCars'))[i][1].name}
                className="img-fluid"
                width="50" />
            </span>
            <h3 className="mb-0">{JSON.parse(localStorage.getItem('currentCars'))[i][1].name}</h3>
            <p className="mb-0">{JSON.parse(localStorage.getItem('currentCars'))[i][0].licencePlate}</p></a></li>)
        }
      }
    }
    return table
  }

  const getExportToExcel = () => {
    const exportArray = [];
    const typeEvent = [];
    const typeStatus = [];

    if(allEvents.next != 'undefined' && allEvents.next != null){
      Object.keys(allEvents.next).map(function(key){
        switch(allEvents.next[key].type){
          case 1:
            typeEvent.push('Periodic maintenance');
          case 2:
            typeEvent.push('MOT');
          case 3:
            typeEvent.push('Roadside assistance');
          case 4:
            typeEvent.push('Roadside assistance');
          case 5:
            typeEvent.push('Extended warranty');
          case 6:
            typeEvent.push('Service contract');
          case 7:
            typeEvent.push('Roadside assistance');
          default:
            typeEvent.push('Unknow');
        }

        switch(currentUser.status){
          case 1:
            typeStatus.push('BRONZE_STATUS');
          case 2:
            typeStatus.push('SILVER_STATUS');
          case 3:
            typeStatus.push('GOLD_STATUS');
          case 4:
            typeStatus.push('PREMIUM_STATUS');
          default:
            typeStatus.push('Unknow');
        }

        exportArray.push({
          lastName: user.last_name,
          firstName: user.first_name,
          status: typeStatus[0],
          type: typeEvent[key],
          dueDate: allEvents.next[key].dueDate,
          reminderDate: allEvents.next[key].reminderDate
        })
      })
      
    }
    const dateDay = new Date();
    const fileName = 'UserTimeline_'+moment(dateDay).format('YYYY/MM/DD');

    return (
      <div className="App">
        <ExportToExcel apiData={exportArray} fileName={fileName} />
      </div>
    );
   }
   
  const content = () => {
    return(
      <div className="row p-4">
        <div className='col-md-12'>
            <div className='p-2'>
                <a href={previousPage} className="button white back align-self-center"><span>{t('Back to client list')}</span></a>
            </div>
        </div>
        <div className="col-lg-4 d-none d-lg-block p-4 without-arrow">
          <div className="status-container status-bronze white-container py-3 px-3 mb-3">
            <div className="overflow-hidden">
              <TropheeNameBlock currentUser={currentUser} userGender={userGender} />
            </div>
            <hr />
            {/* <h3 className="mb-2">{t('my_profil.rewards_history')}</h3> */}
            <RewardBlock currentUser={currentUser} />
          </div>
          <h3 className="text-uppercase">{t('my_profil.my_dealer.title')}</h3>
          <div className="white-container py-2 px-3 mb-3">
            <ul className="list-link-picto">
                <li className="mb-0 py-3 w-100"><a to="/profile/my-dealer" state={{id: currentDealer.id}} className="my-dealer"><span className="icon-dealer-favorite icon"></span><h3 className="mb-0">{currentDealer.name}</h3></a></li>
            </ul>
          </div>
          <h3 className="text-uppercase">{t('my_profil.my_vehicles')}</h3>
          <div className="white-container py-0 px-3 mb-3">
          <ul className="list-link-picto">
              { getCarsMedia()}
          </ul>
          </div>
      </div>
      <div className="col-md-8 p-4">
        <div className="fs-40">
          {getExportToExcel()}  
        </div>
        <div className="white-container">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{t('timeline.title')}</h1>
          <p>{t('timeline.description')}</p>
          <div className="d-flex align-items-center justify-content-between">
            {getCars()}
          </div>
          <hr className="mt-2" />
          {getEvents(loader)}
        </div>
      </div>
    </div>
    )
  };


  let userGender = '';
  if(currentUser) {
    userGender = t('generic.forms.form_options.user_title.'+ currentUser.gender);
  }
  
    return(
      <div className="wrapper">
        <div className="container">
          <main>
            <header className="header py-2">
                <div className="container d-flex align-items-center">
                    <img src={logoNissanNow} alt="Nissan Now" width="160" className="mr-auto logo-width"/>
                    <span className="dark-red cursor-pointer" onClick={() => logout()}><span
                        className="icon-logout-2 icon align-middle"></span>{t('Logout')}</span>
                </div>
            </header>
              <BodyClass name="large-wrapper" />
                {content()}
          </main>
          <footer className="d-flex justify-content-around d-md-block text-center footer">
            {/* <Link to="#" className="mx-md-4" onClick={(e) => toggleModale(e)}>{intl.formatMessage({id: "footer.terms"})}</Link> */}
          </footer>
        </div>
      </div>
    )
};

export default Timeline
